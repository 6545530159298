import { defineStore } from 'pinia'
import { ref } from 'vue'
import JwtService from "@/core/services/JwtService";
import ApiService from "@/core/services/ApiService";

export const useAuth = defineStore('useAuth', () => {

    const user = ref<any>([])
    const isLoggedIn = ref(false)
    const loading = ref(false)

    async function login(password: string, email: string) {
        try {
            loading.value = true

            const response = await ApiService.post(`login`, { password: password, email: email })
            user.value = response.data.data.user
            JwtService.saveToken(response.data.data.token);

            if (user.value != null) {
                isLoggedIn.value = true
            }
        } catch (err) {
            console.log(err)
        }
        loading.value = false
    }

    async function me() {
        try {
            isLoggedIn.value = true

            const response = await ApiService.post(`me`, { token: JwtService.getToken() })
            user.value = response.data.data.user
            JwtService.saveToken(response.data.data.token);
            
            if (user.value == null) {
                isLoggedIn.value = false
            }
        } catch (err) {
            isLoggedIn.value = false
            user.value = []
            JwtService.destroyToken()
        }

    }

    return {
        user,
        loading,
        isLoggedIn,
        login,
        me
    }
})